import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";
import { MDBDataTable } from "mdbreact";
import ApiCallerHelper from "../../../api/apiCallerHelper";
import EVOMDBDataTableHelper from "../../../components/common/evo.mdbDatatable-helper";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import "moment/locale/el";

import "../../../assets/css/datatables.css";

class MechanicIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
      userDict: [],
      datas: [],
    };
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            userDetails: result,
          });
        });

      let mechanicInstances =
        await ApiCallerHelper.Mechanic.getMechanicInstances();

      const instances2 = mechanicInstances.data;
      instances2.forEach(function (part, index) {
        this[index].NewStatus =
          this[index].Status == 1
            ? "1. Αίτηση Σύνδεσης"
            : this[index].Status == 2
            ? "2. Πρόσθετα Δικαιολογητικά"
            : this[index].Status == 3
            ? "3. Τεχνική Αξιολόγηση Ακινήτου"
            : this[index].Status == 4
            ? "4. Σύνταξη Προσφοράς"
            : this[index].Status == 5
            ? "5. Υπογραφή Σύμβασης"
            : this[index].Status == 6
            ? "6. Μηχ.Ολοκλήρωση & Μελέτη εσωτ. εγκατ."
            : this[index].Status == 7
            ? "7. Σύμβαση με Προμηθευτή"
            : this[index].Status == 8
            ? "8. Αίτημα Αυτοψίας"
            : this[index].Status == 9
            ? "9. Ενεργοποίηση εσωτερικής εγκατάστασης"
            : this[index].Status == 10
            ? "10. Φύλλο Καύσης"
            : this[index].Status == 11
            ? "11. Έκδοση άδειας χρήσης"
            : "";
        this[index].Buttons = (
          <React.Fragment>
            <Button
              color="primary"
              href={"/mechanic/details/" + part.Id}
              size="sm"
            >
              Στοιχεία Αίτησης
            </Button>
            <Button
              color="warning"
              href={"/mechanic/instance/" + part.Id}
              size="sm"
            >
              Ενέργειες Αίτησης
            </Button>
            <Button
              color="success"
              href={"/mechanic/comments/" + part.Id}
              size="sm"
            >
              Σχόλια Αίτησης
            </Button>
            <Button
              color="primary"
              href={"/mechanic/files/" + part.Id}
              size="sm"
            >
              Έγγραφα Αίτησης
            </Button>
          </React.Fragment>
        );
      }, instances2);

      EVOMDBDataTableHelper.GridFilter.setStateFilters(
        this,
        "mdbdttable",
        mechanicInstances.data,
        ["Location", "NewStatus"]
      );

      this.setState({
        datas: {
          columns: [
            {
              label: "Αριθμος Αιτησης ▼",
              field: "SerialNumber",
              sort: "asc",
              width: 100,
            },
            {
              label: "Κατασταση ▼",
              field: "NewStatus",
              sort: "asc",
              width: 100,
            },
            {
              label: "ΗΚΑΣΠ ▼",
              field: "Ikasp",
              sort: "asc",
              width: 150,
            },
            {
              label: "Τοποθεσια ▼",
              field: "Location",
              sort: "asc",
              width: 150,
            },
            {
              label: "Μεταβαση ▼",
              field: "Buttons",
              sort: "asc",
              width: 150,
            },
          ],
          rows: mechanicInstances.data,
        },
      });

      const instances = mechanicInstances.data;
      this.setState({ instances });
    }
  }

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Συμβεβλημένες αιτήσεις</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="success"
                        href={"/mechanic/newinstance"}
                        size="sm"
                      >
                        Κατοχύρωση νέας
                      </Button>
                    </div>
                  </Row>
                  <Row className="mt-3">
                    <Col xs="12">
                      <h3 className="mb-2">Φίλτρα</h3>
                    </Col>
                    <br />
                    <EVOMDBDataTableHelper.GridFilter.GridFilterForClassComponent
                      self={this}
                      dataTableId={"mdbdttable"}
                      dataStateKey={"datas"}
                      fields={[
                        {
                          fieldName: "Location",
                          fieldTitle: "ΠΟΛΗ",
                          isIncludeEmptyItem: true,
                        },
                        {
                          fieldName: "NewStatus",
                          fieldTitle: "ΚΑΤΑΣΤΑΣΗ",
                          isIncludeEmptyItem: true,
                        },
                      ]}
                    />
                  </Row>
                  <EVOMDBDataTableHelper.MDBDataTable
                    name="mdbdttable"
                    data={this.state.datas}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <div class="push"></div>
        </Container>
      </>
    );
  }
}

export default MechanicIndex;
