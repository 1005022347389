import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
} from "./apiCallerHelper.core";

import {
  postNewinstance,
  getMechanicInstances,
} from "./apiCallerHelper.mechanic"

export const ApiCallerHelper = {
  hasInitialisedToken: () => {
    return hasInitialisedToken();
  },
  Common: {
    get,
    post,
    delete: del,
  },
  Mechanic: {
    postNewinstance,
    getMechanicInstances,
  },
};

export default ApiCallerHelper;