import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum,
} from "./apiCallerHelper.core";

export async function postNewinstance(serialNumber) {
  var route = "/api/mechanics/newinstance/";
  let data = {
    SerialNumber: serialNumber,
  };
  return postAsync(route, data);
}

export async function getMechanicInstances(serialNumber) {
  var route = "/api/mechanics/instances/";
  return getAsync(route);
}
