import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import React, { useState, useRef, useEffect, Fragment } from "react";
import { Clone } from "../../common/utils";
import { Button } from "reactstrap";
import Collapse from "react-bootstrap/Collapse";
export const EVOMDBDataTableHelper = {
  //applies custom sort logic on mdbDatatable header
  customSort: (self, fieldName, dataSourceFieldName) => {
    self.state.__gridSortDirection = self.state.__gridSortDirection || {};
    self.state.__gridSortDirection[dataSourceFieldName] =
      self.state.__gridSortDirection[dataSourceFieldName] || {};
    self.state.__gridSortDirection[dataSourceFieldName][fieldName] =
      self.state.__gridSortDirection[dataSourceFieldName][fieldName] || 1;
    let direction =
      self.state.__gridSortDirection[dataSourceFieldName][fieldName];
    self.state.__gridSortDirection[dataSourceFieldName][fieldName] =
      direction * -1;

    let dataSource = self.state[dataSourceFieldName];
    dataSource = dataSource.sort((a, b) =>
      a[fieldName] > b[fieldName] ? direction : direction * -1
    );
    self.state[dataSourceFieldName] = dataSource;
    self.setState(self.state);
  },
  //pushes a column in a specific columns array
  addColumnsAfterColumn: (columns, columnFieldName, newColumnDefinitions) => {
    let index = columns.map((x) => x.field).indexOf(columnFieldName);
    newColumnDefinitions.forEach((element) => {
      columns.splice(index + 1, 0, element);
      index += 1;
    });
  },
  GridFilter: {
    _getDistinctValuesSortedAsOptionItem: (field, dataSource) => {
      var distinctFieldValues = Array.from(
        new Set(dataSource.map((x) => x[field]))
      );
      return distinctFieldValues
        .sort((a, b) => {
          if (typeof a != "string") {
            let aStr = String(a);
            let bStr = String(b);
            return aStr.localeCompare(bStr);
          }
          return a.localeCompare(b);
        })
        .map((x) => {
          return {
            value: x,
            label: x,
          };
        });
    },
    //USE THIS TO INITIALLY STATE THE DATASOURCE
    setStateFilters: (self, key, dataSource, fields) => {
      var gridFilter = self.state.gridFilter || {};
      gridFilter[key] = {
        dataSource: dataSource.map((x) => x),
        filters: {},
        distinctFieldValues: {},
      };

      function _setGridFilterDistinctFieldValueOptions(
        fields,
        gridFilterValue
      ) {
        fields.forEach((field) => {
          gridFilterValue.distinctFieldValues[field] =
            EVOMDBDataTableHelper.GridFilter._getDistinctValuesSortedAsOptionItem(
              field,
              gridFilterValue.dataSource
            );
        });
      }

      if (fields) {
        _setGridFilterDistinctFieldValueOptions(fields, gridFilter[key]);
      }

      self.setState({ gridFilter: gridFilter });
    },
    onFilterChanged: (self, key, stateField, value, field) => {
      function _getFilteredDatasource(filters, datasource) {
        for (var key in filters) {
          var filteredValue = filters[key];
          if (!filteredValue) continue;
          try {
            // datasource = datasource.filter((x) => x[key].includes(filteredValue));
            datasource = datasource.filter((x) => {
              if (x[key] != null) {
                return x[key].includes(filteredValue);
              } else {
                if (filteredValue != null) {
                  return false;
                }
                return true;
              }
            });
          } catch (ex) {
            console.error(ex);
          }
        }
        return datasource;
      }

      var stateFilter = self.state.gridFilter[key];
      stateFilter.filters[field] = value;
      var filteredDataSource = _getFilteredDatasource(
        stateFilter.filters,
        stateFilter.dataSource
      );
      self.setState({
        [stateField]: {
          ...self.state[stateField],
          rows: filteredDataSource,
        },
      });
    },
    onFilterChangedFunctional: (self, key, stateField, value, field) => {
      function _getFilteredDatasource(filters, datasource) {
        for (var key in filters) {
          var filteredValue = filters[key];
          if (!filteredValue) continue;
          try {
            // datasource = datasource.filter((x) => x[key].includes(filteredValue));
            datasource = datasource.filter((x) => {
              if (x[key] != null) {
                return x[key].includes(filteredValue);
              } else {
                if (filteredValue != null) {
                  return false;
                }
                return true;
              }
            });
          } catch (ex) {
            console.error(ex);
          }
        }
        return datasource;
      }

      var stateFilter = self.state.gridFilter[key];
      stateFilter.filters[field] = value;
      var filteredDataSource = _getFilteredDatasource(
        stateFilter.filters,
        stateFilter.dataSource
      );
      self.setState({
        [stateField]: {
          ...self.state[stateField],
          rows: filteredDataSource,
        },
      });
    },
    getDistinctFildValuesFromState: (self, key, field, includeEmptyItem) => {
      if (
        !self.state.gridFilter ||
        !self.state.gridFilter[key].distinctFieldValues
      ) {
        return [];
      }

      if (includeEmptyItem) {
        return [
          { value: "", label: " - " },
          ...self.state.gridFilter[key].distinctFieldValues[field],
        ];
      }
      return self.state.gridFilter[key].distinctFieldValues[field];
    },
    getDistinctFildValuesFromSource: (datasource, field, includeEmptyItem) => {
      if (!datasource) {
        return [];
      }
      let results =
        EVOMDBDataTableHelper.GridFilter._getDistinctValuesSortedAsOptionItem(
          field,
          datasource
        );

      if (includeEmptyItem) {
        results = [{ value: "", label: " - " }].concat(results);
      }
      return results;
    },
    GridFilterForClassComponent: ({
      self,
      dataTableId,
      dataStateKey,
      fields,
    }) => {
      let resultDom = [];

      // if (self.state[dataStateKey]?.rows??null){
      //   let fieldNames = fields.map(x=>{return x.fieldName;})
      //   EVOMDBDataTableHelper.GridFilter.setStateFilters(self, dataTableId, self.state[dataStateKey]?.rows, fieldNames);
      // }

      fields.forEach((field, index) => {
        if (!field.fieldName) {
          return;
        }
        field.fieldTitle = field.fieldTitle || field.fieldName;
        field.isIncludeEmptyItem = field.isIncludeEmptyItem || false;
        let filterDom = (
          <Col className="mb-3" md="3" key={index}>
            <Select
              id="SelectFilterCity"
              options={EVOMDBDataTableHelper.GridFilter.getDistinctFildValuesFromState(
                self,
                dataTableId,
                field.fieldName,
                field.isIncludeEmptyItem || false
              )}
              isClearable={true}
              placeholder={field.fieldTitle}
              onChange={(e) => {
                EVOMDBDataTableHelper.GridFilter.onFilterChanged(
                  self,
                  dataTableId,
                  dataStateKey,
                  e?.value,
                  field.fieldName
                );
              }}
            />
          </Col>
        );
        resultDom.push(filterDom);
      });
      return resultDom;
    },
    GridFilterForFunctionalComponent: ({
      id,
      datasource,
      columnDefinitions,
      onFiltersChanged,
      isCollapsable,
    }) => {
      const [_filters, setFilters] = React.useState([]);
      const [_fields, setFields] = React.useState([]);
      const [_isCollapsableOpen, setIsCollapsableOpen] = useState(false);
      const itemsRef = useRef([]);

      useEffect(() => {
        //addGridSearchElementInFilters();
      }, [datasource]);

      /**
       *
       * Adjusts the position of datatables default search and total records
       */
      const addGridSearchElementInFilters = () => {
        if (!id) {
          return;
        }
        const elGridTopItems = document.getElementsByClassName(id)[0];
        const elSearch = elGridTopItems.firstChild.firstChild.firstChild;
        const elPagination = elGridTopItems.firstChild.lastChild.firstChild;
        const cf = document.getElementById(id + "_custom_filters");
        if (cf) {
          const elGrid = document.getElementById(id);
          elGrid.parentNode.insertBefore(cf, elGrid);
        }
        const elCFS = document.getElementById(id + "_custom_filters_Search");
        if (elCFS && elSearch) {
          elCFS.appendChild(elSearch);
        }
        const elCFSe = document.getElementById(
          id + "_custom_filters_Search_end"
        );
        if (elCFSe && elPagination) {
          elCFSe.appendChild(elPagination);
        }
      };

      // let tempLatesFilters = [];
      // function gethasChangedFilters(filters) {
      //   function _arraysEqual(arr1, arr2) {
      //     if (arr1.length !== arr2.length) {
      //       return false;
      //     }
      //     if (arr1.length == 0) {
      //       return true;
      //     }

      //     for (let i = 0; i < arr1.length; i++) {
      //       let x = arr1[i];
      //       let value = arr2.find(y=>y.key == x.key);
      //       if (value != null &&
      //          (x.value != arr2.value)) {
      //           return false;
      //       }
      //     }

      //     return true;
      //   }

      //   if (_arraysEqual(filters, tempLatesFilters) == false) {
      //     tempLatesFilters = Clone(filters);
      //     return true;
      //   }
      //   return false;
      // }

      useEffect(() => {
        /**
         * the code is executed multiple times because the filters are being refreshed causing the datasource to refresh.
         * The gethasChangedFilters is required to avoid self referense refresh (causing the component to refresh all the time)
         */
        if (!onFiltersChanged) {
          return;
        }
        // if (gethasChangedFilters(_filters)) {
        //    onFiltersChanged(_filters);
        // }
        onFiltersChanged(_filters);
      }, [_filters]);

      //set field by column definitions
      useEffect(() => {
        let fields = [];
        columnDefinitions.forEach((columnDefinition) => {
          if (
            columnDefinition.hasFilter &&
            columnDefinition.hasFilter == true
          ) {
            fields.push({
              fieldName: columnDefinition.id,
              fieldTitle: columnDefinition.title,
            });
          }
        });
        setFields(fields);
      }, [columnDefinitions]);

      const onFilterValueChanged = (value, fieldName) => {
        //ADD NEW FILTER TO LIST
        let filters = Clone(_filters);
        // Remove the filter if the value is null
        if (value == null) {
          filters = filters.filter((x) => x.fieldName !== fieldName);
        } else {
          // Update the filter if it exists, otherwise add a new one
          const existingFilter = filters.find((x) => x.fieldName === fieldName);
          if (existingFilter) {
            existingFilter.value = value;
          } else {
            filters.push({
              value: value,
              fieldName: fieldName,
            });
          }
        }
        setFilters(filters);
      };

      let isNoRender = _fields == null || _fields.length == 0;
      if (isNoRender) {
        return;
      }

      /**
       * Clear Filters
       */
      const onClearFilters = () => {
        itemsRef.current.forEach((x) => {
          x.clearValue();
        });
        setFilters([]);
      };

      function getResultDom() {
        let resultDom = [];
        //custom position for search filter
        // let searchDom = (
        //   <Col className="mb-3" md="3" id={id + "_custom_filters_Search"} key={-1}></Col>
        // );
        // resultDom.push(searchDom);

        _fields.forEach((field, index) => {
          if (!field.fieldName) {
            {
              /* dom manipulation do not remove div */
            }
            return <div key={index} />;
          }
          let fieldId = id + "CustomFilter" + field.fieldName;
          field.fieldTitle = field.fieldTitle || field.fieldName;
          field.isIncludeEmptyItem = field.isIncludeEmptyItem || false;
          let filterDom = (
            <Col className="mb-3" md="3" key={index}>
              <Select
                ref={(el) => (itemsRef.current[index] = el)}
                id={fieldId}
                options={EVOMDBDataTableHelper.GridFilter.getDistinctFildValuesFromSource(
                  datasource ?? [],
                  field.fieldName,
                  field.isIncludeEmptyItem || false
                )}
                isClearable={true}
                placeholder={field.fieldTitle}
                onChange={(e) => {
                  onFilterValueChanged(e?.value, field.fieldName);
                }}
              />
            </Col>
          );
          resultDom.push(filterDom);
        });
        //custom position for end filter
        // let endSearchDom = (
        //   <Col className="mb-3" md="3" id={id + "_custom_filters_Search_end"} key={-2}></Col>
        // );
        // resultDom.push(endSearchDom);
        return resultDom;
      }

      let resultDom = getResultDom();

      let collapseId = id + "_custom_filters_collapse";
      let isCollapseButtonVisible = isCollapsable && isCollapsable == true;
      return (
        <Row className="mt-3 mr-0" id={id + "_custom_filters"}>
          <Col xs="12">
            <div className="d-flex">
              <h4 className="mb-0">Φίλτρα</h4>
              {isCollapseButtonVisible == true && (
                <i
                  className="fas fa-filter text-primary ml-2 mt-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsCollapsableOpen(!_isCollapsableOpen)}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Εμφάνιση Φίλτρων"
                  aria-controls={collapseId}
                  aria-expanded={_isCollapsableOpen}
                ></i>
              )}
              <i
                className="fas fa-times text-primary ml-2 mt-2"
                style={{ cursor: "pointer" }}
                onClick={() => onClearFilters()}
                data-toggle="tooltip"
                data-placement="top"
                title="Καθαρισμός Φίλτρων"
              ></i>
            </div>
          </Col>
          {isCollapseButtonVisible == false ? (
            resultDom
          ) : (
            <Col xs="12">
              <Collapse in={_isCollapsableOpen}>
                <Row id={collapseId}>{resultDom}</Row>
              </Collapse>
            </Col>
          )}
        </Row>
      );
    },
  },
  customSortFunctional: (datasource, fieldName) => {
    function _sort(direction) {
      datasource = datasource.sort((a, b) =>
        a[fieldName] > b[fieldName] ? direction : direction * -1
      );
    }

    function _getFirstValue() {
      return datasource[0][fieldName] ?? "";
    }

    //if (!onForceSetGridDatasourceCallback) {return false;}
    let initialSortedValue = _getFirstValue();
    let direction = 1;
    _sort(direction);
    if (initialSortedValue == _getFirstValue()) {
      direction = direction * -1;
      _sort(direction);
    }
    // onForceSetGridDatasourceCallback(datasource);
  },
  MDBDataTable: ({ barReverse, ...fields }) => {
    // useEffect(() => {
    //   if (fields.id){
    //     const el1 = document.getElementsByClassName("tessss");
    //     const el1_1 = el1.firstChild();
    //     const cf = document.getElementById(fields.id + "_custom_filters");
    //     if (cf){
    //       const el = document.getElementById(fields.id);
    //       el.parentNode.insertBefore(cf, el)
    //     }
    //   }

    // }, [data]);
    function hasLastColumnAction() {
      let columns = fields?.data?.columns ?? null;
      if (!columns) {
        return false;
      }
      let columnsLength = columns.length;
      const last = columns[columnsLength - 1];
      if ((last?.field ?? "") == "__action") {
        return true;
      }
      return false;
    }

    let customHeaderClassWidths = "";
    if (hasLastColumnAction()) {
      customHeaderClassWidths = "tool-table--custom-header-width-50";
    }

    return (
      <div className={customHeaderClassWidths}>
        <MDBDataTable
          autoWidth={false}
          id={fields.id}
          searching
          barReverse={barReverse}
          bordered
          exportToCSV={true}
          hover
          strip="true"
          responsive
          entriesOptions={[10, 25, 50, 100, 500, 1000]}
          entries={10}
          nodatacomponent="Δεν υπάρχουν δεδομένα"
          searchLabel="Αναζήτηση"
          snext="Suivant"
          //paginationLabel={["Προηγούμενο", "Επόμενο"]}
          paginationLabel={["<", ">"]}
          infoLabel={["Εμφανίζονται", "έως", "από", "καταχωρήσεις"]}
          entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
          {...fields}
        />
      </div>
    );
  },
};
export default EVOMDBDataTableHelper;
