import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import GenericHeader from "components/Headers/GenericHeader.js";

class MechanicDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      user: [],
      details: [],
      consumer: [],
      meter: [],
      firstStepData: [],
      indoor_setup: "-",
      indoor_setup_completed: "-",
      initialization_date: "-",
      technical_essay_date: "-",
      signature_date: "-",
      indoor_mechanical_check_date: "-",
    };
  }
  async componentDidMount() {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");
      const handle = this.props.match.params.id;

      const userPromise = fetch(
        process.env.REACT_APP_API_LINK + "/api/auth/user/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      ).then((res) => res.json());

      const detailsPromise = fetch(
        process.env.REACT_APP_API_LINK +
          "/api/mechanics/profile/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      ).then((res) => res.json());

      const firstStepDataPromise = fetch(
        process.env.REACT_APP_API_LINK +
          "/api/mechanics/instancedata/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      ).then((res) => res.json());

      await Promise.all([
        userPromise,
        detailsPromise,
        firstStepDataPromise,
      ]).then(([userResult, detailsResult, firstStepDataResult]) => {
        this.setState({
          user: userResult,
          details: detailsResult,
          consumer: detailsResult.consumer,
          meter: detailsResult.meter,
          firstStepData: firstStepDataResult,
          indoor_setup: firstStepDataResult[1].indoor_setup_date.Data,
          indoor_setup_completed:
            firstStepDataResult[1].completed_indoor_setup_date.Data,
          initialization_date: firstStepDataResult[1].initialization_date.Data,
          technical_essay_date:
            firstStepDataResult[1].technical_essay_date.Data,
          signature_date: firstStepDataResult[1].signature_date.Data,
          indoor_mechanical_check_date:
            firstStepDataResult[1].indoor_mechanical_check_date.Data,
        });
      });
    }
  }

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Στοιχεία Αίτησης</h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Βασικες πληροφοριες
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="1">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Α/Α
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={this.state.details.SerialNumber}
                              id="input-username"
                              placeholder="Α/Α"
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Αριθμός ΗΚΑΣΠ
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              value={this.state.details.Ikasp}
                              placeholder="Αριθμός ΗΚΑΣΠ"
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Ημερομηνία Υπογραφής Σύμβασης
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={this.state.signature_date}
                              id="input-email"
                              placeholder="Ημερομηνία Υπογραφής Σύμβασης"
                              type="datetime"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ημερομηνία Ενεργοποίησης
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={this.state.initialization_date}
                              id="input-username"
                              placeholder="Ημερομηνία Ενεργοποίησης"
                              type="text"
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ημερομηνία Θεώρησης Μελέτης
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={this.state.indoor_mechanical_check_date}
                              id="input-username"
                              placeholder="Ημερομηνία Ενεργοποίησης"
                              type="text"
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <hr className="my-4" />
                    <div className="pl-lg-4">
                      <h6 className="heading-small text-muted mb-4">
                        Στοιχεια Μετρητή
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Τύπος Μετρητή
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                value={this.state.meter.Type}
                                placeholder="Τύπος Μετρητή"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Σειριακός Αριθμός
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                value={this.state.meter.SerialNumber}
                                placeholder="Σειριακός Αριθμός"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default MechanicDetails;
