import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

let _title = "Ειδοποίηση";
let _text =
  "Είστε σίγουροι ότι θέλετε να προχωρήσετε με την επιλεγμένη ενέργεια;";

let _successMessageTitle = "Ενημέρωση";
let _successMessage = "Η ενέργεια ολοκληρώθηκε επιτυχώς";
const EVOAlertComponent = {
  Prompt: ({ message, titleMessage }) => {    
    let successMessageTitle = _successMessageTitle;    
    //let successMessage = _successMessage;      
    let text = message || _text;         
    let title = titleMessage || _title;         
    // if (successMessage) {
    //   successMessage = message;
    // }
    return Swal.fire({
      position : "center",
      title: title,
      text: text,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ναι",
      cancelButtonText: "Όχι",
    });
    // .then((result) => {
    //   if (result.isConfirmed) {
    //     if (successCallback) {
    //       successCallback();
    //     }
    //     //   Swal.fire(
    //     //     successMessageTitle,
    //     //     successMessage,
    //     //     'success'
    //     //   )
    //   }
    // });
  },
  Alert: ({ message, titleMessage }) => {    
    let text = message || _text;         
    let title = titleMessage || _title;
    return Swal.fire({
      position : "center",
      title: title,
      text: text,            
      confirmButtonColor: "#3085d6",      
      confirmButtonText: "OK",      
    });
  },
  DefaultError: ({ message }) => {    
    let text = message || "Παρουσιάστηκε σφάλμα επικοινωνίας. Παρακαλούμε προσπαθήστε ξανά";         
    let title = "Ενημέρωση"
    return Swal.fire({
      position : "center",
      title: title,
      text: text,            
      confirmButtonColor: "#3085d6",      
      confirmButtonText: "OK",      
    });
  },
};

export default EVOAlertComponent;
