import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import MechanicLayout from "layouts/Mechanic.js";

import Login from "views/examples/Login.js";

import SignUp from "views/examples/signup.js";

function setToken(userToken) {
  localStorage.setItem("token", userToken);
}

function App() {
  const token = localStorage.getItem("token");
  if (!token) {
    if (window.location.href.includes("signup")) {
      <BrowserRouter>
        <Switch>
          <Route path="/signup" render={(props) => <SignUp {...props} />} />
        </Switch>
      </BrowserRouter>;
    } else {
      return <Login setToken={setToken} />;
    }
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" render={(props) => <Login {...props} />} />
        <Route
          path="/mechanic"
          render={(props) => <MechanicLayout {...props} />}
        />
        <Route path="/signup" render={(props) => <SignUp {...props} />} />
        <Redirect from="/" to="/mechanic/index" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
