import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import { CommonLoading } from "react-loadingg";
import Moment from "moment";
import axios from "axios";
import GlobalParametersHelper from "../../../common/GlobalParametersHelper";
class MechanicInstance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      user: [],
      details: [],
      filesStudy: [],
      filesTechnical: [],
      filesDeclaration: [],
      filesCertification: [],
      filesCombustionSheet: [],
      removeActions: 0,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            user: result,
          });
        });

      const handle = this.props.match.params.id;
      fetch(
        process.env.REACT_APP_API_LINK +
        "/api/workflowinstances/details/" +
        handle +
        "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              details: result,
            });

            fetch(
              process.env.REACT_APP_API_LINK + "/api/mechanics/files/status/",
              {
                method: "POST",
                headers: new Headers({
                  Authorization: "Bearer " + localStorage.getItem("token"),
                }),
                body: JSON.stringify({
                  SerialNumber: result.SerialNumber,
                  Step: result.Status,
                }),
              }
            )
              .then((res) => res.json())
              .then((res) => {
                this.setState({
                  loading: false,
                  filesStudy: res.filesStudy,
                  filesTechnical: res.filesTechnical,
                  filesDeclaration: res.filesDeclaration,
                  filesCertification: res.filesCertification,
                  filesCombustionSheet: res.filesCombustionSheet,
                });
              });
          },
          (error) => {
            this.setState({
              error,
            });
          }
        );
    }
  }

  viewFile = async (fileId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_LINK}/api/mechanics/files/presigned-url/${fileId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      if (data.url) {
        window.open(data.url, "_blank"); // Open the presigned URL in a new tab
      }
    } catch (error) {
      console.error("Failed to get presigned URL", error);
    }
  };

  deleteFile = (fileId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(process.env.REACT_APP_API_LINK + "/api/files/" + fileId + "/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          fetch(
            process.env.REACT_APP_API_LINK + "/api/mechanics/files/status/",
            {
              method: "POST",
              headers: new Headers({
                Authorization: "Bearer " + localStorage.getItem("token"),
              }),
              body: JSON.stringify({
                SerialNumber: this.state.details.SerialNumber,
                Step: this.state.details.Status,
              }),
            }
          )
            .then((res) => res.json())
            .then((res) => {
              this.setState({
                loading: false,
                filesStudy: res.filesStudy,
                filesTechnical: res.filesTechnical,
                filesDeclaration: res.filesDeclaration,
                filesCertification: res.filesCertification,
                filesCombustionSheet: res.filesCombustionSheet,
              });
            });
        });
    }
  };

  async uploadFile(evt, fileId) {
    const formData = new FormData();
    const res = fileId.split("/");

    // Append the file to the formData
    formData.append("file", evt.target.files[0]);

    // Append additional data to formData
    formData.append("serial_number", this.state.details.SerialNumber);
    formData.append("step_id", res[0]);
    formData.append("action_id", res[1]);


    fetch(process.env.REACT_APP_API_LINK + "/api/mechanics/file/new/", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: formData
    }).then((data) => {
      fetch(
        process.env.REACT_APP_API_LINK + "/api/mechanics/files/status/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify({
            SerialNumber: this.state.details.SerialNumber,
            Step: this.state.details.Status,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          this.setState({
            loading: false,
            filesStudy: res.filesStudy,
            filesTechnical: res.filesTechnical,
            filesDeclaration: res.filesDeclaration,
            filesCertification: res.filesCertification,
            filesCombustionSheet: res.filesCombustionSheet,
          });
        });
    });
  }

  submitInstance = (instanceId) => {
    this.setState({ removeActions: 1 });

    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
        "/api/mechanics/submit/" +
        this.state.details.Id +
        "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          window.location.reload(false);
        });
    }
  };

  dynamicCall(call) {
    if (call == "req_aut") {
      fetch(
        process.env.REACT_APP_API_LINK +
        "/api/dynamic/autopsy/" +
        this.state.details.Id +
        "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {});
    }
  }

  SubmitButtonsHelper = {
    IsSubmitAvailable: () => {
      let status = this.state.details.Status;
      return (
        (status == 6 || status == 8 || status == 10) &&
        !this.state.details.Paused
      );
      // && !this.state.removeActions
    },
    IsSubmitInternalInstallationAvailable: () => {
      return this.state.details.Status == 6 && !this.state.details.Paused;
    },
  };

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Ενέργειες Αίτησης</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.SubmitButtonsHelper.IsSubmitAvailable() && (
                        <Button
                          color="success"
                          onClick={() => {
                            this.submitInstance();
                          }}
                          size="sm"
                        >
                          Υποβολή
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                {this.SubmitButtonsHelper.IsSubmitInternalInstallationAvailable() ? (
                  <CardBody>
                    <Form>
                      <div className="pl-lg-4">
                        <h6 className="heading-small text-muted mb-4">
                          6. Μελετη εσωτερικης εγκαταστασης
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="3">
                              <br />
                              <label
                                className="btn btn-primary"
                                htmlFor={"study-file"}
                              >
                                Μελέτη εσωτερικής εγκατάστασης
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="study-file"
                                style={{ visibility: "hidden" }}
                                onChange={(evt) =>
                                  this.uploadFile(
                                    evt,
                                    "31304e7b-3003-4bcf-8358-5cc0f689b795/2a287fce-ff4c-4d2d-9a54-ed724a6c64a8"
                                  )
                                }
                                type="file"
                              />
                            </Col>

                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Ονομα</th>
                                  <th scope="col">Ημερομηνια Υποβολης</th>
                                  <th scope="col">Ενεργειες</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(this.state?.filesStudy ?? [])?.map(
                                  (dynamicFiles) => (
                                    <tr>
                                      <td>{dynamicFiles.FileName}</td>
                                      <td>
                                        {Moment(dynamicFiles.UploadDate)
                                          .locale("el")
                                          .format("dddd, DD MMM YYYY")}
                                      </td>
                                      <td>
                                        <Button
                                          color="primary"
                                          onClick={() => this.viewFile(dynamicFiles.Id)}
                                          size="sm"
                                          title="Προβολή"
                                        >
                                          <i className="fas fa-eye" />
                                        </Button>
                                        <Button
                                          color="danger"
                                          onClick={() => {
                                            this.deleteFile(dynamicFiles.Id);
                                          }}
                                          size="sm"
                                          title="Διαγραφή"
                                        >
                                          <i className="fas fa-trash" />
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Row>

                          {/* <Row>
                            <Col lg="3">
                              <br />
                              <label className="btn btn-primary" htmlFor={"technical-file"}>
                                Υποβολή Τεχνικής Έκθεσης
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="technical-file"
                                style={{ visibility: "hidden" }}
                                onChange={evt => this.uploadFile(evt, '31304e7b-3003-4bcf-8358-5cc0f689b795/ce2051f0-73a9-4fc3-9375-aea390d7258a')}
                                type='file'
                              />
                            </Col>

                            <Table className="align-items-center table-flush" responsive>
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Ονομα</th>
                                  <th scope="col">Ημερομηνια Υποβολης</th>
                                  <th scope="col">Ενεργειες</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.filesTechnical.map((dynamicFiles) =>
                                    <tr>
                                      <td>{dynamicFiles.FileName}</td>
                                      <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                      <td>
                                        <Button
                                          color="primary"
                                          href={dynamicFiles.Link}
                                          size="sm"
                                          title="Προβολή"
                                        >
                                          <i className="fas fa-eye" />
                                        </Button>
                                        <Button
                                          color="danger"
                                          onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                          size="sm"
                                          title="Διαγραφή"
                                        >
                                          <i className="fas fa-trash" />
                                        </Button>
                                      </td>
                                    </tr>
                                  )

                                }
                              </tbody>
                            </Table>
                          </Row> */}
                          <br />
                          <br />
                          <br />
                          <Col lg="12">
                            <h3>Σημείωση :</h3>
                            <p>
                              <i>
                                Η μελέτη πρέπει να αναρτάται σαν ενιαίο αρχείο
                                σε μορφή pdf, ψηφιακά υπογεγραμμένο. Πρόσθετα
                                διευκρινιστικά αρχεία για τη μελέτη μπορούν να
                                αναρτώνται στο ίδιο πεδίο
                              </i>
                            </p>
                          </Col>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                ) : (
                  <>
                    {this.state.details.Status == 8 &&
                      !this.state.details.Paused ? (
                      <CardBody>
                        <Form>
                          <div className="pl-lg-4">
                            <h6 className="heading-small text-muted mb-4">
                              8. Αίτημα Αυτοψίας
                            </h6>
                            <div className="pl-lg-4">
                              <Row>
                                <Col lg="3">
                                  <br />
                                  <label
                                    className="btn btn-primary"
                                    htmlFor={"decl-file"}
                                  >
                                    Yπεύθυνη δήλωση μηχανικού για κατασκευή και
                                    Πιστοποιητικό Εσωτερικής Εγκατάστασης
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="decl-file"
                                    style={{ visibility: "hidden" }}
                                    onChange={(evt) =>
                                      this.uploadFile(
                                        evt,
                                        "27d10905-bc43-4ad2-8f53-8ca69bc09a38/6e33127d-4506-4b87-b6d9-d77d2086e4bb"
                                      )
                                    }
                                    type="file"
                                  />
                                </Col>

                                <Table
                                  className="align-items-center table-flush"
                                  responsive
                                >
                                  <thead className="thead-light">
                                    <tr>
                                      <th scope="col">Ονομα</th>
                                      <th scope="col">Ημερομηνια Υποβολης</th>
                                      <th scope="col">Ενεργειες</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this?.state?.filesDeclaration?.map(
                                      (dynamicFiles) => (
                                        <tr>
                                          <td>{dynamicFiles.FileName}</td>
                                          <td>
                                            {Moment(dynamicFiles.UploadDate)
                                              .locale("el")
                                              .format("dddd, DD MMM YYYY")}
                                          </td>
                                          <td>
                                            <Button
                                              color="primary"
                                              onClick={() => this.viewFile(dynamicFiles.Id)}
                                              size="sm"
                                              title="Προβολή"
                                            >
                                              <i className="fas fa-eye" />
                                            </Button>
                                            <Button
                                              color="danger"
                                              onClick={() => {
                                                this.deleteFile(
                                                  dynamicFiles.Id
                                                );
                                              }}
                                              size="sm"
                                              title="Διαγραφή"
                                            >
                                              <i className="fas fa-trash" />
                                            </Button>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </Row>

                              <Row>
                                <Col lg="3">
                                  <br />
                                  <label
                                    className="btn btn-primary"
                                    htmlFor={"cert-file"}
                                  >
                                    Αίτηση Διενέργειας Αυτοψίας
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="cert-file"
                                    style={{ visibility: "hidden" }}
                                    onChange={(evt) => {
                                      this.uploadFile(
                                        evt,
                                        "27d10905-bc43-4ad2-8f53-8ca69bc09a38/76e99563-afaf-4de8-8ef9-9238264aa038"
                                      );
                                      this.dynamicCall("req_aut");
                                    }}
                                    type="file"
                                  />
                                </Col>

                                <Table
                                  className="align-items-center table-flush"
                                  responsive
                                >
                                  <thead className="thead-light">
                                    <tr>
                                      <th scope="col">Ονομα</th>
                                      <th scope="col">Ημερομηνια Υποβολης</th>
                                      <th scope="col">Ενεργειες</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this?.state?.filesCertification?.map(
                                      (dynamicFiles) => (
                                        <tr>
                                          <td>{dynamicFiles.FileName}</td>
                                          <td>
                                            {Moment(dynamicFiles.UploadDate)
                                              .locale("el")
                                              .format("dddd, DD MMM YYYY")}
                                          </td>
                                          <td>
                                            <Button
                                              color="primary"
                                              onClick={() => this.viewFile(dynamicFiles.Id)}
                                              size="sm"
                                              title="Προβολή"
                                            >
                                              View
                                            </Button>
                                            <Button
                                              color="danger"
                                              onClick={() => {
                                                this.deleteFile(
                                                  dynamicFiles.Id
                                                );
                                              }}
                                              size="sm"
                                              title="Διαγραφή"
                                            >
                                              <i className="fas fa-trash" />
                                            </Button>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </Row>
                              <br />
                              <br />
                              <br />
                              <Col lg="12">
                                <h3>Σημείωση :</h3>
                                <p>
                                  <i>
                                    Για την υποβολή αιτήματος αυτοψίας ο
                                    Επιβλέπων Μηχανικός ανεβάζει το Αίτημα
                                    Διενέργειας Αυτοψίας που συνοδεύεται από το
                                    Έντυπο Τεχνικής Έκθεσης για Σύνδεση και το
                                    Πρόγραμμα Λειτουργίας και Συντήρησης της
                                    εγκατάστασης σύμφωνα με το ΚΕΦΑΛΑΙΟ 12 του
                                    τεχνικού κανονισμού ΦΕΚ 976/Β/28.03.12 και
                                    ανάλογα με την εγκατάσταση.
                                  </i>
                                </p>
                              </Col>
                            </div>
                          </div>
                        </Form>
                      </CardBody>
                    ) : (
                      <>
                        {this.state.details.Status == 10 &&
                          !this.state.details.Paused ? (
                          <>
                            <CardBody>
                              <Form>
                                <div className="pl-lg-4">
                                  <h6 className="heading-small text-muted mb-4">
                                    10. Φυλλο Καυσης
                                  </h6>
                                  <div className="pl-lg-4">
                                    {/* <Row>
                                      <Col lg="6">
                                        <Button
                                          color="primary"
                                          onClick={evt => this.dynamicCall('req_aut')}
                                          size="lg"
                                        >
                                          Αίτημα Αυτοψίας
                                        </Button>
                                      </Col>
                                    </Row> */}

                                    <Row>
                                      <Col lg="3">
                                        <br />
                                        <label
                                          className="btn btn-primary"
                                          htmlFor={"decl-file"}
                                        >
                                          Ανάρτηση Φύλλου Καύσης
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="decl-file"
                                          style={{ visibility: "hidden" }}
                                          onChange={(evt) =>
                                            this.uploadFile(
                                              evt,
                                              "794e87ef-999e-4781-8ade-2f181a5ca1ce/0bdc62fa-edbb-4e37-87b7-83fb3509cc25"
                                            )
                                          }
                                          type="file"
                                        />
                                      </Col>

                                      <Table
                                        className="align-items-center table-flush"
                                        responsive
                                      >
                                        <thead className="thead-light">
                                          <tr>
                                            <th scope="col">Ονομα</th>
                                            <th scope="col">
                                              Ημερομηνια Υποβολης
                                            </th>
                                            <th scope="col">Ενεργειες</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this?.state?.filesCombustionSheet?.map(
                                            (dynamicFiles) => (
                                              <tr>
                                                <td>{dynamicFiles.FileName}</td>
                                                <td>
                                                  {Moment(
                                                    dynamicFiles.UploadDate
                                                  )
                                                    .locale("el")
                                                    .format(
                                                      "dddd, DD MMM YYYY"
                                                    )}
                                                </td>
                                                <td>
                                                  <Button
                                                    color="primary"
                                                    onClick={() => this.viewFile(dynamicFiles.Id)}
                                                    size="sm"
                                                    title="Προβολή"
                                                  >
                                                    <i className="fas fa-eye" />
                                                  </Button>
                                                  <Button
                                                    color="danger"
                                                    onClick={() => {
                                                      this.deleteFile(
                                                        dynamicFiles.Id
                                                      );
                                                    }}
                                                    size="sm"
                                                    title="Διαγραφή"
                                                  >
                                                    <i className="fas fa-trash" />
                                                  </Button>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    </Row>

                                    {/* <Row>
                                      <Col lg="3">
                                        <br />
                                        <label className="btn btn-primary" htmlFor={"cert-file"}>
                                          Πιστοποιητικό Εσωτερικής Εγκατάστασης
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="cert-file"
                                          style={{ visibility: "hidden" }}
                                          onChange={evt => this.uploadFile(evt, '27d10905-bc43-4ad2-8f53-8ca69bc09a38/76e99563-afaf-4de8-8ef9-9238264aa038')}
                                          type='file'
                                        />
                                      </Col>

                                      <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                          <tr>
                                            <th scope="col">Ονομα</th>
                                            <th scope="col">Ημερομηνια Υποβολης</th>
                                            <th scope="col">Ενεργειες</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            this.state.filesCertification.map((dynamicFiles) =>
                                              <tr>
                                                <td>{dynamicFiles.FileName}</td>
                                                <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                                <td>
                                                  <Button
                                                    color="primary"
                                                    href={dynamicFiles.Link}
                                                    size="sm"
                                                    title="Προβολή"
                                                  >
                                                    <i className="fas fa-eye" />
                                                  </Button>
                                                  <Button
                                                    color="danger"
                                                    onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                    size="sm"
                                                    title="Διαγραφή"
                                                  >
                                                    <i className="fas fa-trash" />
                                                  </Button>
                                                </td>
                                              </tr>
                                            )
                                          }
                                        </tbody>
                                      </Table>
                                    </Row> */}
                                    {/* <br />
                                    <br />
                                    <br />
                                    <Col lg="12">
                                      <h3>Σημείωση :</h3>
                                      <p><i>Για την υποβολή αιτήματος αυτοψίας είναι απαραίτητη η ανάρτηση υπεύθυνης δήλωσης για την κατασκευή εσωτερικής εγκατάστασης και πιστοποιητικού στεγανότητας.</i></p>
                                    </Col> */}
                                  </div>
                                </div>
                              </Form>
                            </CardBody>
                          </>
                        ) : (
                          <>
                            <CardBody>
                              <Form>
                                <div className="pl-lg-4">
                                  <h6 className="heading-small text-muted mb-4"></h6>
                                  <div className="pl-lg-4">
                                    <Row>
                                      <Col lg="6">
                                        <p>
                                          Δεν υπάρχει κάποια διαθέσιμη ενέργεια
                                          αυτή τη στιγμή.
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Form>
                            </CardBody>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default MechanicInstance;
