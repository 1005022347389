import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import "moment/locale/el";
import axios from "axios";

class MechanicDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      files: [],
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Πρότυπα Έγγραφα και Πληροφορίες</h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="8" xl="2"></Col>
                    <Col lg="8" xl="4">
                      <Card
                        className="card-stats mb-4 mb-xl-0"
                        onClick={() =>
                          window.open(
                            "https://deda.gr/wp-content/uploads/2021/12/%CE%94%CE%B9%CE%B5%CF%85%CE%BA%CF%81%CE%B9%CE%BD%CE%B7%CF%83%CE%B5%CE%B9%CF%82_%CE%A0%CE%B1%CF%81%CE%B1%CF%84%CE%B7%CF%81%CE%AE%CF%83%CE%B5%CE%B9%CF%821.pdf",
                            "_blank"
                          )
                        }
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Διευκρινισεις - Παρατηρησεις
                              </CardTitle>
                              <span className="h5 mb-0">
                                Οι συνηθέστερες παρατηρήσεις που μπορεί να βγουν
                                κατά την θεώρηση μιας μελέτης.
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-question" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="8" xl="4">
                      <Card
                        className="card-stats mb-4 mb-xl-0"
                        onClick={() =>
                          window.open(
                            "https://deda.gr/wp-content/uploads/2021/12/%CE%A6%CE%AC%CE%BA%CE%B5%CE%BB%CE%BF%CF%82-%CE%9C%CE%B5%CE%BB%CE%AD%CF%84%CE%B7%CF%82-.pdf",
                            "_blank"
                          )
                        }
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Φακελος Μελετης
                              </CardTitle>
                              <span className="h5 mb-0">
                                Αφορά στο τι πρέπει να περιέχει ένας φάκελος
                                μελέτης. <br />
                                <br />
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-folder-open" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="8" xl="2">
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="8" xl="2"></Col>
                    <Col lg="8" xl="4">
                      <Card
                        className="card-stats mb-4 mb-xl-0"
                        onClick={() =>
                          window.open(
                            "https://deda.gr/wp-content/uploads/2021/12/1.-%CE%9C%CE%95%CE%9B%CE%95%CE%A4%CE%97.pdf",
                            "_blank"
                          )
                        }
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Προτυπο τευχος μελετης - τεχνικης εκθεσης
                              </CardTitle>
                              <span className="h5 mb-0"></span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-file-pdf" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="8" xl="4">
                      <Card
                        className="card-stats mb-4 mb-xl-0"
                        onClick={() =>
                          window.open(
                            "https://deda.gr/wp-content/uploads/2022/11/ΑΙΤΗΜΑ-ΔΙΕΝΕΡΓΕΙΑΣ-ΑΥΤΟΨΙΑΣ-ΣΥΝΔΕΣΗΣ-.docx",
                            "_blank"
                          )
                        }
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                ΑΙΤΗΣΗ ΔΙΕΝΕΡΓΕΙΑΣ ΑΥΤΟΨΙΑΣ
                              </CardTitle>
                              <span className="h5 mb-0"></span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-file-word" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="8" xl="2">
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="8" xl="2"></Col>
                    <Col lg="8" xl="4">
                      <Card
                        className="card-stats mb-4 mb-xl-0"
                        onClick={() =>
                          window.open(
                            "https://deda.gr/wp-content/uploads/2022/03/ΥΔ-ΞΕΝΗ-ΙΔΙΟΚΤΗΣΙΑ.docx",
                            "_blank"
                          )
                        }
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                ΥΠΕΥΘΥΝΗ ΔΗΛΩΣΗ ΣΥΝΑΙΝΕΣΗΣ ΓΙΑ ΟΔΕΥΣΗ
                              </CardTitle>
                              <span className="h5 mb-0"></span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-file-word" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="8" xl="4">
                      <Card
                        className="card-stats mb-4 mb-xl-0"
                        onClick={() =>
                          window.open(
                            "https://deda.gr/wp-content/uploads/2024/02/ΕΝΤΥΠΟ-ΤΕΧΝΙΚΗΣ-ΕΚΘΕΣΗΣ-ΓΙΑ-ΣΥΝΔΕΣΗ1.xlsx",
                            "_blank"
                          )
                        }
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                ΕΝΤΥΠΟ ΤΕΧΝΙΚΗΣ ΕΚΘΕΣΗΣ ΓΙΑ ΣΥΝΔΕΣΗ
                              </CardTitle>
                              <span className="h5 mb-0"></span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-file-word" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="8" xl="2">
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="8" xl="2"></Col>
                    <Col lg="8" xl="4">
                      <Card
                        className="card-stats mb-4 mb-xl-0"
                        onClick={() =>
                          window.open(
                            "https://deda.gr/wp-content/uploads/2024/02/ΔΗΛΩΣΗ-ΑΝΑΘΕΣΗΣ-ΜΕΛΕΤΗΣ-ΕΠΙΒΛΕΨΗΣ.docx",
                            "_blank"
                          )
                        }
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                ΥΠΕΥΘΥΝΗ ΔΗΛΩΣΗ ΑΝΑΘΕΣΗΣ - ΑΝΑΛΗΨΗΣ
                              </CardTitle>
                              <span className="h5 mb-0"></span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-file-word" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col lg="8" xl="4"> 
                      <Card className="card-stats mb-4 mb-xl-0" onClick={() => window.open('https://deda.gr/wp-content/uploads/2022/11/ΔΗΛΩΣΗ-ΑΝΑΛΗΨΗΣ.docx', "_blank") }>
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                ΥΠΕΥΘΥΝΗ ΔΗΛΩΣΗ ΑΝΑΛΗΨΗΣ
                              </CardTitle>
                              <span className="h5 mb-0">
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-file-word" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col lg="8" xl="2">
                      <br />
                    </Col>
                  </Row>
                  <Col lg="12">
                    <h3>Σημείωση :</h3>
                    <p>
                      <i>
                        Μαζί με τα έγγραφα "ΑΙΤΗΜΑ ΔΙΕΝΕΡΓΕΙΑΣ ΑΥΤΟΨΙΑΣ
                        ΣΥΝΔΕΣΗΣ" και "ΕΝΤΥΠΟ ΤΕΧΝΙΚΗΣ ΕΚΘΕΣΗΣ ΓΙΑ ΣΥΝΔΕΣΗ", να
                        υποβάλλεται και το "Πρόγραμμα Λειτουργίας και Συντήρησης
                        της Εγκατάστασης" σύμφωνα με το ΚΕΦΑΛΑΙΟ 12 του τεχνικού
                        κανονισμού ΦΕΚ 976/Β/28.03.12
                      </i>
                    </p>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default MechanicDocuments;
