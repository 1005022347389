import React, { useState } from "react";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Row,
  Table,
  Col,
  Button,
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import { CommonLoading } from "react-loadingg";
import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import "moment/locale/el";

import axios from "axios";

class MechanicComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      step: 0,
      comments: [],
      showModal: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;
    if (localStorage.getItem("token")) {
      const result1 = await axios
        .get(
          process.env.REACT_APP_API_LINK +
            "/api/mechanics/comments/" +
            handle +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.setState({
            step6comments: res.data.step6,
            step8comments: res.data.step8,
            loading: false,
          });
        });
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <InstanceHeader />
          <div
            className="header pb-4"
            style={{
              background: "linear-gradient(to right, #00467F, #5CBC59)",
            }}
          >
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col lg="8" xl="12">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            ></CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              Ιστορικό Σχολίων
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <Container className="mt--4" fluid>
            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardBody>
                    <div>
                      <Col xs="8">
                        <h3 className="mb-0">
                          {
                            "Μηχανική Ολοκλήρωση & Μελέτη εσωτερικής εγκατάστασης"
                          }
                        </h3>
                        <p></p>
                      </Col>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Σχολιο</th>
                            <th scope="col">Ημερομηνια Δημιουργιας</th>
                            {/* <th scope="col">Πεδιο Βηματος</th> */}
                            {/* <th scope="col">Ενεργειες</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.step6comments.map((dynamicComment) => (
                            <tr>
                              <td>{dynamicComment.Comment}</td>
                              <td>
                                {Moment(dynamicComment.CreatedAt)
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                              </td>
                              {/* <td>{dynamicAction.Name}</td> */}
                              {/* <td>
                                                <Button
                                                    color="success"
                                                    onClick={() => { this.checkComment(dynamicComment.Id) }}
                                                    size="sm"
                                                    title="Επίλυση"
                                                >
                                                    <i className="fas fa-check" />
                                                </Button> 
                                                <Button
                                                    color="danger"
                                                    onClick={() => { this.deleteComment(dynamicComment.Id) }}
                                                    size="sm"
                                                    title="Διαγραφή"
                                                >
                                                    <i className="fas fa-trash" />
                                                </Button> 
                                            </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <hr />

                      <Col xs="8">
                        <h3 className="mb-0">
                          {"Ενεργοποίηση εσωτερικής εγκατάστασης "}
                        </h3>
                        <p></p>
                      </Col>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Σχολιο</th>
                            <th scope="col">Ημερομηνια Δημιουργιας</th>
                            {/* <th scope="col">Πεδιο Βηματος</th> */}
                            {/* <th scope="col">Ενεργειες</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.step8comments.map((dynamicComment) => (
                            <tr>
                              <td>{dynamicComment.Comment}</td>
                              <td>
                                {Moment(dynamicComment.CreatedAt)
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                              </td>
                              {/* <td>{dynamicAction.Name}</td> */}
                              {/* <td>
                                                <Button
                                                    color="success"
                                                    onClick={() => { this.checkComment(dynamicComment.Id) }}
                                                    size="sm"
                                                    title="Επίλυση"
                                                >
                                                    <i className="fas fa-check" />
                                                </Button> 
                                                <Button
                                                    color="danger"
                                                    onClick={() => { this.deleteComment(dynamicComment.Id) }}
                                                    size="sm"
                                                    title="Διαγραφή"
                                                >
                                                    <i className="fas fa-trash" />
                                                </Button> 
                                            </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <hr />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default MechanicComments;
