import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

import Modal from 'react-modal';
// core components

import { CommonLoading } from 'react-loadingg';
import Moment from "moment";
import axios from 'axios';

import GenericHeader from "components/Headers/GenericHeader.js";

import '../../assets/css/signup.css';

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            user: [],
            details: [],
            name: "",
            lastname: "",
            email: "",
            password1: "",
            password2: "",
            phoneNumber: "",
            address: "",
            verificationcode: "",
            terms: false,
            errormsg: 0,
            getInitialState: false,
            close: false,
            open: false,
            showModal1: false,
            showModal2: false,
            fullfilledName: false,
            fullfilledSurname: false,
            fullfilledEmail: false,
            fullfilledPhoneNumber: false,
            fullfilledPassword: false,
            fullfilledPasswordConfirmation: false,
            fullfilledTerms: false,
            fullfilledBaseAddress: false,
            tokensms: "",
            digit4: '',
            digit4_correct: '',
            data_time_returned: ''
        };

        this.changeName = this.changeName.bind(this);
        this.changeLastname = this.changeLastname.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changePassword1 = this.changePassword1.bind(this);
        this.changePassword2 = this.changePassword2.bind(this);
        this.changePhoneNumber = this.changePhoneNumber.bind(this);
        this.changeverificationcode = this.changeverificationcode.bind(this);
        this.sendcodetoVerify = this.sendcodetoVerify.bind(this);
        this.changeBaseAddress = this.changeBaseAddress.bind(this);
        // this.tokensms = this.changetokensms.bind(this);
        // this.changeTerms = this.changeTerms.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.close2 = this.close2.bind(this);
        this.open2 = this.open2.bind(this);
    }

    changeverificationcode(event) {
        if (event.target.value.trim() == '') {
            let input_password_validation_repeat_error = document.getElementById('input-password-validation-repeat-error');
            input_password_validation_repeat_error.innerHTML = 'Το πεδίο είναι απαραίτητο';
            input_password_validation_repeat_error.style.display = "block";
            this.setState({ fullfilledPasswordConfirmation: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }

        else if (event.target.value != this.state.password1) {
            let input_password_validation_repeat_error = document.getElementById('input-password-validation-repeat-error');
            input_password_validation_repeat_error.innerHTML = 'Οι κωδικοί δεν ταιριάζουν';
            input_password_validation_repeat_error.style.display = "block";
            this.setState({ fullfilledPasswordConfirmation: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }

        else if (event.target.value == this.state.password1) {
            document.getElementById('input-password-validation-repeat-error').style.display = "none";
            this.setState({ fullfilledPasswordConfirmation: true });

            if (this.allFieldsFullFilled())
                document.getElementById('submit-button-register').removeAttribute('disabled');
            else
                document.getElementById('submit-button-register').setAttribute('disabled', '');
        }
        this.setState({ verificationcode: event.target.value });
    }

    sendcodetoVerify(event, code) {
        this.setState({ verificationcode: event.target.value });
        { this.RequestSMSVerificationCode() }
        if (code.value.trim() == "") {
            document.getElementById("statusmessage").innerHTML = "<p style='color:red'>Πρέπει να συμπληρώσετε το πεδίο του κωδικού.</p>";
        }
        else {
            document.getElementById("statusmessage").innerHTML = "<p style='color:green'>Ο κωδικός που ππληκτρολογήσατε είναι ο" + code.value + "</p>";
        }
    }

    changeName(event) {
        if (event.target.value.trim() == '') {
            let input_username_validation_error = document.getElementById('input-username-validation-error');
            input_username_validation_error.innerHTML = 'Το πεδίο είναι απαραίτητο';
            input_username_validation_error.style.display = "block";
            this.setState({ fullfilledName: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }

        else {
            document.getElementById('input-username-validation-error').style.display = "none";
            this.setState({ fullfilledName: true });

            this.allFieldsFullFilled();
        }
        this.setState({ name: event.target.value });
    }

    changeBaseAddress(event) {
        if (event.target.value.trim() == '') {
            let input_baseaddress_validation_error = document.getElementById('input-baseaddress-validation-error');
            input_baseaddress_validation_error.innerHTML = 'Το πεδίο είναι απαραίτητο';
            input_baseaddress_validation_error.style.display = "block";
            this.setState({ fullfilledBaseAddress: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }

        else {
            document.getElementById('input-baseaddress-validation-error').style.display = "none";
            this.setState({ fullfilledBaseAddress: true });

            this.allFieldsFullFilled();
        }
        this.setState({ address: event.target.value });
    }

    changeLastname(event) {
        if (event.target.value.trim() == '') {
            let input_surname_validation_error = document.getElementById('input-surname-validation-error');
            input_surname_validation_error.innerHTML = 'Το πεδίο είναι απαραίτητο';
            input_surname_validation_error.style.display = "block";
            this.setState({ fullfilledSurname: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }

        else {
            document.getElementById('input-surname-validation-error').style.display = "none";
            this.setState({ fullfilledSurname: true });

            this.allFieldsFullFilled();
        }
        this.setState({ lastname: event.target.value });
    }

    changeEmail(event) {
        var validRegex = /^\S+@\S+\.\S+$/;

        if (event.target.value.trim() == '') {
            let email_validation_error_element = document.getElementById('email-validation-error');
            email_validation_error_element.innerHTML = 'Το πεδίο είναι απαραίτητο';
            email_validation_error_element.style.display = "block";
            this.setState({ fullfilledEmail: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }

        else if (!event.target.value.match(validRegex)) {
            let email_validation_error_element = document.getElementById('email-validation-error');
            email_validation_error_element.innerHTML = 'Το email δεν είναι έγκυρο';
            email_validation_error_element.style.display = "block";
            this.setState({ fullfilledEmail: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }

        else {
            document.getElementById('email-validation-error').style.display = "none";

            fetch(process.env.REACT_APP_API_LINK + '/api/mechanics/emailcheck/' + event.target.value, {
                method: 'POST',
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 'email_not_exists') {
                        this.setState({ fullfilledEmail: true });
                        this.allFieldsFullFilled();
                    }
                    else if (data.status == 'email_exists') {
                        let email_validation_error_element = document.getElementById('email-validation-error');
                        email_validation_error_element.innerHTML = 'Το συγκεκριμένο email χρησιμοποιείται ήδη';
                        email_validation_error_element.style.display = "block";
                        this.setState({ fullfilledEmail: false });
                        document.getElementById('submit-button-register').setAttribute('disabled', '');
                    }

                })


        }

        this.setState({ email: event.target.value });
    }

    // changeTerms(event){
    //   this.setState({terms: event.target.checked});
    //   this.setState({fullfilledTerms: event.target.checked});
    //   this.allFieldsFullFilled();
    // }

    changePassword1(event) {
        document.getElementById("message").style.display = "block";

        let letter_complete = false;
        let capital_complete = false;
        let number_complete = false;
        let length_complete = false;


        var letter = document.getElementById("letter");
        var capital = document.getElementById("capital");
        var number = document.getElementById("number");
        var length = document.getElementById("length");

        if (event.target.value.trim() == '') {
            document.getElementById("message").style.display = "none";
            let input_password_validation_error = document.getElementById('input-password-validation-error');
            input_password_validation_error.innerHTML = 'Το πεδίο είναι απαραίτητο';
            input_password_validation_error.style.display = "block";
            this.setState({ fullfilledPassword: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }

        else {
            document.getElementById("message").style.display = "block";
            var lowerCaseLetters = /[a-z]/g;
            if (event.target.value.match(lowerCaseLetters)) {
                letter.classList.remove("invalid");
                letter.classList.add("valid");

                letter_complete = true;
            } else {
                letter.classList.remove("valid");
                letter.classList.add("invalid");

                letter_complete = false;
            }

            var upperCaseLetters = /[A-Z]/g;
            if (event.target.value.match(upperCaseLetters)) {
                capital.classList.remove("invalid");
                capital.classList.add("valid");

                capital_complete = true;
            } else {
                capital.classList.remove("valid");
                capital.classList.add("invalid");

                capital_complete = false;
            }

            // Validate numbers
            var numbers = /[0-9]/g;
            if (event.target.value.match(numbers)) {
                number.classList.remove("invalid");
                number.classList.add("valid");

                number_complete = true;
            } else {
                number.classList.remove("valid");
                number.classList.add("invalid");

                number_complete = false;
            }

            // Validate length
            if (event.target.value.length >= 8) {
                length.classList.remove("invalid");
                length.classList.add("valid");

                length_complete = true;
            } else {
                length.classList.remove("valid");
                length.classList.add("invalid");

                length_complete = false;
            }

            document.getElementById('input-password-validation-error').style.display = "none";

            if (
                (letter_complete == true) &&
                (number_complete == true) &&
                (capital_complete == true) &&
                (length_complete == true)
            ) {
                this.setState({ fullfilledPassword: true });
            }

            else
                this.setState({ fullfilledPassword: false });


            if (this.state.fullfilledPasswordConfirmation) {
                if (event.target.value.trim() != this.state.verificationcode) {
                    const flag = this.allFieldsFullFilled();

                    if (flag)
                        document.getElementById('submit-button-register').setAttribute('disabled', '');
                }
                else {
                    const flag = this.allFieldsFullFilled();

                    if (flag) {
                        if (
                            (letter_complete == true) &&
                            (number_complete == true) &&
                            (capital_complete == true) &&
                            (length_complete == true)
                        )
                            document.getElementById('submit-button-register').removeAttribute('disabled');
                    }

                    else
                        document.getElementById('submit-button-register').setAttribute('disabled', '');
                }
            }
        }
        this.setState({ password1: event.target.value });
    }

    changePassword2(event) {
        this.setState({ password2: event.target.value });
        //H pano grammi den douleuei sync.
        if (this.state.password1.toString() != event.target.value) {
            this.setState({ errormsg: 1 })
        }
        else {
            this.setState({ errormsg: 0 })
        }
    }

    changePhoneNumber(event) {
        // input-phonenumber-validation-error
        var phoneno = /^[0-9]+$/;

        if (event.target.value.trim() == '') {
            let input_phonenumber_validation_error = document.getElementById('input-phonenumber-validation-error');
            input_phonenumber_validation_error.innerHTML = 'Το πεδίο είναι απαραίτητο';
            input_phonenumber_validation_error.style.display = "block";
            this.setState({ fullfilledPhoneNumber: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }

        else if (!event.target.value.match(phoneno)) {
            document.getElementById('input-phonenumber-validation-error').innerHTML = 'Ο αριθμός τηλεφώνου δεν είναι έγκυρος';
            document.getElementById('input-phonenumber-validation-error').style.display = "block";
            this.setState({ fullfilledPhoneNumber: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }
        else if (event.target.value.length != 10) {
            document.getElementById('input-phonenumber-validation-error').innerHTML = 'Ο αριθμός τηλεφώνου δεν είναι έγκυρος';
            document.getElementById('input-phonenumber-validation-error').style.display = "block";
            this.setState({ fullfilledPhoneNumber: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }
        else {
            document.getElementById('input-phonenumber-validation-error').style.display = "none";
            this.setState({ fullfilledPhoneNumber: true });

            if (
                (this.state.fullfilledName == true) &&
                (this.state.fullfilledSurname == true) &&
                (this.state.fullfilledEmail == true) &&
                (this.state.fullfilledPassword == true) &&
                (this.state.fullfilledPasswordConfirmation == true)
                // (this.state.fullfilledTerms == true)
            ) {
                document.getElementById('submit-button-register').removeAttribute('disabled');
            }
        }
        this.setState({ phoneNumber: event.target.value });
    }

    NewConsumer = applicationId => {
        if (!localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/consumer/new/', {
                method: 'POST',
                // headers: new Headers({
                //     'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                // }),
                body: JSON.stringify({
                    'Name': this.state.name,
                    'LastName': this.state.lastname,
                    'Email': this.state.email,
                    'Password': this.state.password1,
                    'phoneNumber': this.state.phoneNumber
                })
            })
                .then(data => {
                    this.setState({
                        ticketSent: 1
                    })
                    setTimeout(function () {
                        window.location.replace("/consumer/index/")
                    }, 3500);
                })
        }
    }
    CheckifUserEmailExists = nothing => {
        fetch(process.env.REACT_APP_API_LINK + '/api/mechanics/newmechanicverificationemail/', {
            method: 'POST',
            body: JSON.stringify({
                'Name': this.state.name,
                'LastName': this.state.lastname,
                'Email': this.state.email,
                'Phone': this.state.phoneNumber,
                'BaseAddress': this.state.address,
                'Password': this.state.password1
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    digit4_correct: data.digit4,
                    data_time_returned: data.date_time
                });
                this.open();
            })

    }

    RequestSMSVerificationCode = applicationId => {
        // alert(process.env.REACT_APP_API_LINK);
        if (document.getElementById("input-phonenumber").value.trim() != "") {
            fetch(process.env.REACT_APP_API_LINK + '/api/sms/token/', {
                method: 'POST',
                // headers: new Headers({
                //     'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                // }),
                body: JSON.stringify({
                    'client': process.env.REACT_APP_SMS_CREDS_CLIENT,
                    'secret': process.env.REACT_APP_SMS_CREDS_SECRET
                })
            })
                .then(res => res.json())
                .then(data => {
                    // var verificationcode = document.getElementById("").value;
                    // alert(verificationcode);
                    this.VerifyCode(data.token);
                    setTimeout(function () {
                        //window.location.replace("/consumer/index/")
                    }, 3500);
                })
            this.open();
        }
        else {
            alert("No Number");
        }
    }
    VerifyCode = tokensms => {
        fetch(process.env.REACT_APP_API_LINK + '/api/sms/validatesend/', {
            method: 'POST',
            body: JSON.stringify({
                'client': process.env.REACT_APP_SMS_CREDS_CLIENT,
                'secret': process.env.REACT_APP_SMS_CREDS_SECRET,
                'token': tokensms,
                'email': this.state.email,
                'phone': this.state.phoneNumber,
                'country_code': '30'
            })
        })
            .then(res => res.json())
            .then(data => {
                this.state.tokensms = tokensms;
                this.setState({
                    ticketSent: 1
                })
                setTimeout(function () {
                    //window.location.replace("/consumer/index/")
                }, 3500);
            })
    }
    ValidateCode = digit4 => {
        const now = new Date().toISOString();
        const datetime_now = Date.parse(now);
        const datetime_returned = Date.parse(this.state.data_time_returned + 'Z');


        const difference = Math.floor((datetime_now - datetime_returned) / 1000);

        if ((difference <= 300) && (this.state.digit4_correct == this.state.digit4)) {
            fetch(process.env.REACT_APP_API_LINK + '/api/mechanics/new/', {
                method: 'POST',
                body: JSON.stringify({
                    'Name': this.state.name,
                    'LastName': this.state.lastname,
                    'Email': this.state.email,
                    'Phone': this.state.phoneNumber,
                    'BaseAddress': this.state.address,
                    'Password': this.state.password1
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.Id) {
                        document.getElementById("statusmessage").innerHTML = "<p style='color:green'>Ο χρήστης δημιουργήθηκε με επιτυχία. Μπορείτε να κάνετε login <a href='../../'>ΕΔΩ</a><p>";
                        document.getElementById("btnValidate4Digit").disabled = true;
                    }
                    else {
                        document.getElementById("statusmessage").innerHTML = "<p style='color:red'>Το email που έχετε εισάγει χρησιμοποιείται ήδη! Παρακαλούμε εισάγετε διαφορετικό email. <p>";
                        document.getElementById("btnValidate4Digit").disabled = false;
                    }
                })
        }
        else {
            document.getElementById("statusmessage").innerHTML = `<p style='color:red'>Ο κωδικός που εισάγατε δεν είναι σωστός ή πέρασε το χρονικό διάστημα των 5 λεπτών. Παρακαλούμε εισάγετε τον σωστό κωδικό <p> `;
            document.getElementById("resend-code").style.display = 'block';
        }

    }

    allFieldsFullFilled() {
        if (
            (this.state.fullfilledName == true) &&
            (this.state.fullfilledSurname == true) &&
            (this.state.fullfilledEmail == true) &&
            (this.state.fullfilledPhoneNumber == true) &&
            (this.state.fullfilledPassword == true) &&
            (this.state.fullfilledBaseAddress == true)
            // (this.state.fullfilledTerms == true)
        ) {
            document.getElementById('submit-button-register').removeAttribute('disabled');
            return true;
        }
        else {
            document.getElementById('submit-button-register').setAttribute('disabled', '');
            return false;
        }
    }

    close() {
        this.setState({ showModal: false });
    }

    open() {
        //alert(document.getElementById('input-username').value);
        this.setState({ showModal: true });
    }
    close2() {
        this.setState({ showModal2: false });
    }

    open2() {
        //alert(document.getElementById('input-username').value);
        this.setState({ showModal2: true });
    }

    change4digit = e => {
        this.setState({
            digit4: e.target.value
        });
    }

    render() {
        window.onload = () => {
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        };
        return (
            <>
                <GenericHeader />
                <Container className="mt--9" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Εγγραφή νέου χρήστη</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            {/* <Button
                        color="success"
                        size="sm"
                        id="submit-button-register"
                        onClick={() => { this.RequestSMSVerificationCode(); }}
                      >
                        <span className="fa fa-eye"></span>&nbsp;Εγγραφή
                            </Button> */}
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            Βασικα στοιχεια
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4"></Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-name"
                                                        >
                                                            Όνομα
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue=""
                                                            id="input-name"
                                                            placeholder="Όνομα"
                                                            type="text"
                                                            onChange={this.changeName}
                                                        />
                                                        <span id={"input-username-validation-error"} style={{ color: "red", display: "none" }}></span>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4"></Col>
                                                <Col lg="4"></Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-surname"
                                                        >
                                                            Επίθετο
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue=""
                                                            id="input-surname"
                                                            placeholder="Επίθετο"
                                                            type="text"
                                                            onChange={this.changeLastname}
                                                        />
                                                        <span id={"input-surname-validation-error"} style={{ color: "red", display: "none" }}></span>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4"></Col>
                                                <Col lg="4"></Col>
                                                <Col lg="4">
                                                    <FormGroup>

                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Email
                                                        </label>

                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue=""
                                                            id="input-email"
                                                            placeholder="email@example.com"
                                                            type="email"
                                                            onChange={this.changeEmail}
                                                        />
                                                        <span id={"email-validation-error"} style={{ color: "red", display: "none" }}></span>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4"></Col>
                                                <Col lg="4"></Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-phonenumber"
                                                        >
                                                            Διεύθυνση Επαγγελματικής Έδρας
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue=""
                                                            id="input-baseaddress"
                                                            placeholder="Διεύθυνση Επαγγελματικής Έδρας"
                                                            type="text"
                                                            onChange={this.changeBaseAddress}
                                                        />
                                                        <span id={"input-baseaddress-validation-error"} style={{ color: "red", display: "none" }}></span>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4"></Col>
                                                <Col lg="4"></Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-phonenumber"
                                                        >
                                                            Αρ. Τηλεφώνου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue=""
                                                            id="input-phonenumber"
                                                            placeholder="69ΧΧΧΧΧΧΧΧ"
                                                            type="phonenumber"
                                                            onChange={this.changePhoneNumber}
                                                        />
                                                        <span id={"input-phonenumber-validation-error"} style={{ color: "red", display: "none" }}></span>
                                                    </FormGroup>
                                                </Col>


                                            </Row>
                                        </div>

                                        <hr className="my-4" />
                                        <h6 className="heading-small text-muted mb-4">
                                            Κωδικος προσβασης
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="4"></Col>
                                                <Col md="4" style={{ alignTextAlign: "center" }}>
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-password"
                                                        >
                                                            Νέος κωδικός πρόσβασης
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue=""
                                                            id="input-password"
                                                            type="password"
                                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                            onChange={this.changePassword1}
                                                        />
                                                        <span id={"input-password-validation-error"} style={{ color: "red", display: "none" }}></span>
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <div className='password_messages_container'>
                                                <div id="message" style={{ width: '400px' }}>
                                                    <h3>Ο κωδικός πρέπει να περιέχει:</h3>
                                                    <p id="letter" className="invalid"><b>Πεζούς</b> χαρακτήρες</p>
                                                    <p id="capital" className="invalid"><b>Κεφαλαίους</b> χαρακτήρες</p>
                                                    <p id="number" className="invalid">Τουλάχιστον 1 <b>αριθμό</b></p>
                                                    <p id="length" className="invalid">Τουλάχιστον <b>8 ψηφία</b></p>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col md="4"></Col>
                                                <Col md="4" style={{ alignTextAlign: "center" }}>
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-password-verification"
                                                        >
                                                            Επιβεβαίωση νέου κωδικού πρόσβασης
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue=""
                                                            id="input-password-verification"
                                                            type="password"
                                                            onChange={this.changeverificationcode}
                                                        />
                                                        <span id={"input-password-validation-repeat-error"} style={{ color: "red", display: "none" }}></span>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {this.state.errormsg ?
                                                <Row>
                                                    <Col md="4"></Col>
                                                    <Col md="4" style={{ alignTextAlign: "center" }}>
                                                        <p style={{ color: "red" }}>Οι Κωδικοί δεν ταιριαζουν.</p>
                                                    </Col>
                                                </Row> : <></>
                                            }

                                        </div>

                                        <br />
                                        <div className="col-md-12" style={{ textAlign: 'center' }}>
                                            {/* <p>Με την εγγραφή σας, συμφωνείτε με τους όρους και τις Προϋποθέσεις</p> */}
                                            <p>Με την εγγραφή σας, συμφωνείτε με τους  <a href="https://deda.gr/wp-content/uploads/2021/07/Privacy-policy_-%CE%94%CE%95%CE%94%CE%91-30.7.2021.pdf">Όρους και τις Προϋποθέσεις</a></p>
                                        </div>
                                        <div
                                            style={{ display: 'flex', justifyContent: 'center' }}
                                        >
                                            <br />
                                            <Button
                                                color="success"
                                                size="lg"
                                                id="submit-button-register"
                                                onClick={() => { this.CheckifUserEmailExists(); }}

                                            >
                                                <span className="fa fa-arrow-right"></span>&nbsp;Εγγραφή
                                            </Button>
                                        </div>

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    id="Mdl1"
                    isOpen={this.state.showModal}
                    ariaHideApp={false}
                    contentLabel="Info Modal"
                    style={{
                        overlay: {

                        },
                        content: {
                            position: 'absolute',
                            // top: '30%',
                            // left: '30%',
                            // marginTop: '-50px',
                            // marginLeft: '-30px',
                            // width: '45%',
                            // height: '45%'
                            margin: '0 auto',
                            maxWidth: '80%',
                            width: '700px',
                            height: '510px'
                        }
                    }}
                >
                    <ModalHeader>Επιβεβαίωση Χρήστη</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Col md="12" style={{ alignTextAlign: "center" }}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-address"
                                        size="large"
                                    >
                                        Σας έχει αποσταλλεί στο email που δηλώσατε ( {this.state.email} ) ένας 4ψήφιος κωδικός μεσω Email.
                                        Παρακαλούμε συμπληρώστε τον στο παρακάτω πεδίο.
                                    </label>
                                    <Input
                                        id="4digit_verification"
                                        type="text"
                                        placeholder="4-ψήφιος κωδικός"
                                        maxLength="4"
                                        className="form-control-alternative"
                                        defaultValue=""
                                        onChange={e => { this.change4digit(e) }}
                                    />
                                    <br />
                                    <Button
                                        type="button"
                                        onClick={() => this.ValidateCode(this.state.digit4)}
                                        className="btn btn-success"
                                        id="btnValidate4Digit"
                                        style={{marginBottom: '10px'}}
                                        >
                                        

                                        Επιβεβαίωση
                                    </Button>
                                    <div id="statusmessage"></div>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        id="resend-code"
                                        style={{display: 'none'}}
                                        onClick={() => { this.CheckifUserEmailExists(); }}

                                    >
                                        <span className="fa fa-arrow-right"></span>&nbsp;Αποστολή νέου κωδικού
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.close}
                            size="sm"
                            title="Κλείσιμο"
                        >
                            <i className="fas fa-times" /> &nbsp; Κλείσιμο
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

}

export default SignUp;