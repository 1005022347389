import React from "react";
import ReactDOM from "react-dom";
import App from "./views/App.js";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "./assets/css/swal2.css";
import {EvoLoadingIndicator} from "./components/Abstract/EvoLoadingIndicator";
import {Version} from "./CopyrightVersion";

// ReactDOM.render(<App />, document.getElementById("root"));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <div className="tool-theme" data-version={Version.getVersion()} data-date_created={Version.getVersionDate()}>      
        <App />
        <EvoLoadingIndicator />            
    </div>
  ); 