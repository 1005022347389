import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

class NewResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      result: null,
    };
  }

  componentDidMount() {
    const handle = this.props.match.params.id;
    this.setState({
      result: handle,
    });
  }

  // setIkasp = evt => {
  //     if (localStorage.getItem('token')) {
  //         fetch(process.env.REACT_APP_API_LINK + '/api/suppliers/newinstance/', {
  //             method: 'POST',
  //             headers: new Headers({
  //                 'Authorization' : 'Bearer ' + localStorage.getItem('token'),
  //             }),
  //             body: JSON.stringify({
  //                 'Ikasp': evt.target.value
  //             })
  //         })
  //         .then(res => res.json())
  //         .then(data => {
  //             if (data.Result == 1) {
  //             window.location.replace("/supplier/result/1");
  //             }
  //             else {
  //             window.location.replace("/supplier/result/0");
  //             }
  //         })
  //     }
  // }

  MechanicalResponse = ({ stateResult }) => {
    const responses = [
      { value: null, className: "text-danger", message: "Λάθος Καταχώρηση" },
      {
        value: -2,
        className: "text-warning",
        message:
          "H αίτηση που καταχωρίσατε εκπροσωπείται από τον ίδιο μηχανικό.",
      },
      {
        value: -1,
        className: "text-danger",
        message:
          "H αίτηση που καταχωρίσατε εκπροσωπείται από άλλο μηχανικό. Προκειμένου να ζητήσετε αλλαγή εκπροσώπησης, παρακαλώ στείλτε mail στο meletes@deda.gr",
      },
      {
        value: 0,
        className: "text-danger",
        message: "Δεν υπάρχει ο αριθμός αίτησης που δηλώσατε.",
      },
      {
        value: 1,
        className: "text-success",
        message: "Δηλώσατε επιτυχώς τον αριθμό αίτησης",
      },
    ];

    let response = responses[0];
    responses.forEach((responseItem) => {
      if (responseItem.value == stateResult) {
        response = responseItem;
      }
    });
    return (
      <Col xs="8">
        <h3 className={"mb-0 " + response.className}> {response.message}</h3>
      </Col>
    );
  };

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--5" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <this.MechanicalResponse
                            stateResult={this.state.result}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default NewResult;
