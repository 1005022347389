import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import axios from "axios";

import Moment from "moment";
import "moment/locale/el";
import EVOAlertComponent from "../../../components/Abstract/EvoAlertComponent";
import ApiCallerHelper from "../../../api/apiCallerHelper";
import RedirectHelper from "../../../common/redirectHelper";
import GlobalParametersHelper from "../../../common/GlobalParametersHelper";
class NewInstance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      roles: [],
      files: [],
      sn: 0,
      fileUpload: null,
      dummy: false,
    };
  }

  async componentDidMount() {}

  updateSN(evt) {
    this.setState({
      sn: evt.target.value,
    });
  }

  setRequest = async (evt) => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      let serialNumber = this.state.sn;
      let data = await ApiCallerHelper.Mechanic.postNewinstance(serialNumber);
      RedirectHelper.RedirectTo.mechanicResult(data.Result);
    }
  };

  viewFile = async (fileId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_LINK}/api/mechanics/files/presigned-url/${fileId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      if (data.url) {
        window.open(data.url, "_blank"); // Open the presigned URL in a new tab
      }
    } catch (error) {
      console.error("Failed to get presigned URL", error);
    }
  };

  deleteFile = (fileId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(process.env.REACT_APP_API_LINK + "/api/files/" + fileId + "/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          fetch(
            process.env.REACT_APP_API_LINK + "/api/mechanics/file/status/",
            {
              method: "POST",
              headers: new Headers({
                Authorization: "Bearer " + localStorage.getItem("token"),
              }),
              body: JSON.stringify({
                SerialNumber: this.state.sn,
                StepId: "31304e7b-3003-4bcf-8358-5cc0f689b795",
                ActionId: "890ee0d5-66a0-4dfe-842a-8891d28bfe6c",
              }),
            }
          )
          .then((result) => result.json())
          .then((result) => {
            this.setState({
              loading: false,
              files: result,
              dummy: true,
            });
          })
        });
    }
  };

  async uploadFile(evt, fileId) {
    const formData = new FormData();
    const res = fileId.split("/");

    // Append the file to the formData
    formData.append("file", evt.target.files[0]);

    // Append additional data to formData
    console.log(this.state, res)
    formData.append("serial_number", this.state.sn);
    formData.append("step_id", res[0]);
    formData.append("action_id", res[1]);


    fetch(process.env.REACT_APP_API_LINK + "/api/mechanics/file/new/", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: formData
    }).then((data) => {
      fetch(
        process.env.REACT_APP_API_LINK + "/api/mechanics/file/status/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify({
            SerialNumber: this.state.sn,
            StepId: res[0],
            ActionId: res[1],
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          this.setState({
            loading: false,
            files: res
          });
        });
    });
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Καταχώρηση νέας αίτησης</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="success"
                        href="#pablo"
                        onClick={(evt) => {
                          this.setRequest(evt);
                        }}
                        size="sm"
                      >
                        Κατοχύρωση
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    {/* <h6 className="heading-small text-muted mb-4">
                      Βασικα στοιχεια
                    </h6> */}
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Αριθμός Αίτησης
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="Αριθμός Αίτησης"
                              type="number"
                              onChange={(evt) => this.updateSN(evt)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <br />
                          <label
                            className="btn btn-primary"
                            htmlFor={"mech-file"}
                          >
                            Υπεύθυνη Δήλωση
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="mech-file"
                            style={{ visibility: "hidden" }}
                            onChange={(evt) =>
                              this.uploadFile(
                                evt,
                                "31304e7b-3003-4bcf-8358-5cc0f689b795/890ee0d5-66a0-4dfe-842a-8891d28bfe6c"
                              )
                            }
                            type="file"
                          />
                        </Col>
                      </Row>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Ονομα</th>
                            <th scope="col">Ημερομηνια Υποβολης</th>
                            <th scope="col">Ενεργειες</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.files.map((dynamicFiles) => (
                            <tr>
                              <td>{dynamicFiles.FileName}</td>
                              <td>
                                {Moment(dynamicFiles.UploadDate)
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                              </td>
                              <td>
                                <Button
                                  color="primary"
                                  onClick={() => this.viewFile(dynamicFiles.Id)}
                                  size="sm"
                                  title="Προβολή"
                                >
                                  <i className="fas fa-eye" />
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={() => {
                                    this.deleteFile(dynamicFiles.Id);
                                  }}
                                  size="sm"
                                  title="Διαγραφή"
                                >
                                  <i className="fas fa-trash" />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <br />
                      <br />
                      <br />
                      <Col lg="12">
                        <h3>Σημείωση :</h3>
                        <p>
                          <i>
                            Όπως αναρτηθούν υπογεγραμμένες οι ΥΔ ανάθεσης/
                            εκπροσώπησης.
                          </i>
                        </p>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default NewInstance;
