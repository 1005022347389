import React, { useState } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import "moment/locale/el";
import axios from "axios";

class InstanceDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      files: [],
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    await axios
      .get(
        process.env.REACT_APP_API_LINK +
          "/api/mechanics/allfiles/" +
          handle +
          "/",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((res) => {
        this.setState({
          loading: false,
          files: res.data,
        });
      });
  }

  viewFile = async (fileId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_LINK}/api/mechanics/files/presigned-url/${fileId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      if (data.url) {
        window.open(data.url, "_blank"); // Open the presigned URL in a new tab
      }
    } catch (error) {
      console.error("Failed to get presigned URL", error);
    }
  };

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Αρχεία</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Ονομα Αρχειου</th>
                      <th scope="col">Ημερομηνια Υποβολης</th>
                      <th scope="col">Ενεργειες</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.files.map((dynamicData) => (
                      <tr>
                        <th scope="row"> {dynamicData.FileName}</th>
                        <td> {dynamicData.UploadDate} </td>
                        <td>
                          <Button
                            color="primary"
                            onClick={() => this.viewFile(dynamicData.Id)}
                            size="sm"
                          >
                            Προβολή Αρχείου
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default InstanceDocuments;
