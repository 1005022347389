import Login from "views/examples/Login.js";
import Logout from "views/examples/Logout.js";
import MechanicIndex from "views/examples/mechanic/mechanicIndex.js";
import NewInstance from "views/examples/mechanic/newInstance.js";
import NewResult from "views/examples/mechanic/newResult";
import MechanicDetails from "views/examples/mechanic/mechanicDetails.js"
import MechanicInstance from "views/examples/mechanic/mechanicInstance.js";
import MechanicInstances from "views/examples/mechanic/mechanicInstances.js";
import MechanicPending from "views/examples/mechanic/mechanicPending.js";
import MechanicDocuments from "views/examples/mechanic/mechanicDocuments.js";
import MechanicComments from "views/examples/mechanic/mechanicComments.js";
import InstanceDocuments from "views/examples/mechanic/instanceDocuments";
import MechanicProfile from "views/examples/mechanic/mechanicProfile.js";
import MechanicSettings from "views/examples/mechanic/mechanicSettings.js";
import MechanicSupport from "views/examples/mechanic/mechanicSupport.js";
import SignUp from "views/examples/signup.js";

var routes = [
  {
    path: "/index",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: MechanicIndex,
    layout: "/mechanic"
  },
  {
    path: "/newinstance",
    name: "Κατοχύρωση Νέας",
    icon: "ni ni-circle-08 text-pink",
    component: NewInstance,
    layout: "/mechanic"
  },
  {
    path: "/result/:id",
    name: "Αποτέλεσμα Κατοχύρωσης",
    icon: "ni ni-circle-08 text-pink",
    component: NewResult,
    layout: "/mechanic"
  },
  {
    path: "/instances",
    name: "Αιτήσεις",
    icon: "ni ni-circle-08 text-pink",
    component: MechanicInstances,
    layout: "/mechanic"
  },
  {
    path: "/instance/:id",
    name: "Αίτηση",
    icon: "ni ni-circle-08 text-pink",
    component: MechanicInstance,
    layout: "/mechanic"
  },
  {
    path: "/details/:id",
    name: "Αίτηση",
    icon: "ni ni-circle-08 text-pink",
    component: MechanicDetails,
    layout: "/mechanic"
  },
  {
    path: "/pending",
    name: "Αιτήσεις",
    icon: "ni ni-circle-08 text-pink",
    component: MechanicPending,
    layout: "/mechanic"
  },
  {
    path: "/files/:id",
    name: "Αιτήσεις",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceDocuments,
    layout: "/mechanic"
  },
  {
    path: "/comments/:id",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: MechanicComments,
    layout: "/mechanic"
  },
  {
    path: "/documents",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: MechanicDocuments,
    layout: "/mechanic"
  },
  {
    path: "/profile",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: MechanicProfile,
    layout: "/mechanic"
  },
  {
    path: "/settings",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: MechanicSettings,
    layout: "/mechanic"
  },
  {
    path: "/support",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: MechanicSupport,
    layout: "/mechanic"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-key-25 text-info",
    component: Logout,
    layout: "/auth"
  },
  {
    path: "/signup",
    name: "SignUp",
    icon: "ni ni-key-25 text-info",
    component: SignUp,
    layout: "/auth"
  },
];

export default routes;
