export const RedirectHelper = {
  RedirectTo: {
    reloadPage: () => {
      window.location.reload();
    },
    reloadPageFromCache: () => {
      window.location.reload(false);
    },
    // home: () => _location("/basicLayout/index/"),
    // login: () => _location("/auth/login"),
    mechanicResult: (resultId) => _location("/mechanic/result/" + resultId),  
  },
};

  
  function _locationRedirectReact(self, route) {
    self.props.history.push(route)
  }
  
  function _location(route) {
    window.location.replace(route);
  }

  function _openWindow(route) {
    window.open(window.location.origin + route, '_blank');      
  }

export default RedirectHelper;